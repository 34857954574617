import { createAction, props } from '@ngrx/store';
import { ProjectDescriptionUnit, ProjectInstance, QrCodeProjectInstanceUnitObject, UnitDropdown } from '../models';
import { UnitProductModel } from '@cue/admin-units';

export const projectSelected = createAction(
  '[Projects] Project selected',
  props<{
    projectId: string;
  }>(),
);
export const loadProjectInstances = createAction(
  '[Projects] Load project instances',
  props<{
    projectId: string;
  }>(),
);
export const projectInstancesLoaded = createAction(
  '[Projects] Project instances and permissions loaded',
  props<{
    instances: ProjectInstance[];
    permissions: QrCodeProjectInstanceUnitObject[];
  }>(),
);
export const projectRefreshStatuses = createAction(
  '[Projects] Project refresh statuses',
  props<{
    units: UnitDropdown[];
  }>(),
);
export const setProjectInstances = createAction('[Projects] Project set new instances', props<{ instances: ProjectInstance[] }>());
export const addNewProjectInstance = createAction('[Projects] Project add new instance', props<{ instance: ProjectInstance }>());
export const deleteProjectInstance = createAction('[Projects] Project delete instance', props<{ instanceId: string }>());
export const projectInstanceChanged = createAction(
  '[Projects] Project instance changed',
  props<{ value: { name: string; developerMode: boolean }; instanceId: string }>(),
);
export const projectInstanceUnitIdChanged = createAction(
  '[Projects] Project instance unit id changed',
  props<{ unitId: number; instanceId: string; instanceUnitId: string; units: UnitDropdown[] }>(),
);
// upload new project description
export const applyNewInstanceTemplate = createAction(
  '[Projects] Apply new instance template',
  props<{ template: ProjectDescriptionUnit[]; unitProductModels: UnitProductModel[] }>(),
);
// parameters
export const projectInstanceUnitParameterChanged = createAction(
  '[Projects] Project instance unit parameter changed',
  props<{ value: string; instanceId: string; instanceUnitId: string; parameterName: string; useDefault: boolean }>(),
);
export const projectInstanceUnitImportParameters = createAction(
  '[Projects] Project instance unit import parameters',
  props<{ id: string; row: any; useDefaultTranslation: string }>(),
);
// objects
export const projectInstanceUnitObjectChanged = createAction(
  '[Projects] Project instance unit object changed',
  props<{ qrCodeIds: string[]; instanceId: string; instanceUnitId: string; objectId: string }>(),
);
export const projectInstanceUnitObjectAddQRCodes = createAction(
  '[Projects] Project instance unit object add QR codes',
  props<{ qrCodeIds: string[]; instanceUnitId: string; objectId: string }>(),
);
export const projectInstanceUnitImportObjects = createAction(
  '[Projects] Project instance unit import objects',
  props<{ id: string; row: any; qrCodes: { id: string; uniqueName: string }[] }>(),
);
// instances grid
export const setExpandedInstanceIds = createAction('[Projects] Project set expanded instance ids', props<{ ids: string[] }>());
export const addNewExpandedInstanceId = createAction('[Projects] Project add new expanded instance id', props<{ id: string }>());
// objects + permissions
export const setPermissions = createAction('[Projects] Set new Permissions', props<{ permissions: QrCodeProjectInstanceUnitObject[] }>());
export const addNewQrCodeAndObjectPermissions = createAction(
  '[Projects] Permissions add object and qr code',
  props<{ newItems: QrCodeProjectInstanceUnitObject[] }>(),
);
export const removeQrCodeAndObjectPermissions = createAction(
  '[Projects] Permissions remove object and qr code',
  props<{ ids: string[] }>(),
);
export const qrCodeAndObjectValueChanged = createAction(
  '[Projects] QR code and object permissions value change',
  props<{
    id: string;
    value: { grantAccessId: number; minutesBeforeEvent: number; minutesAfterEvent: number; userIds: number[]; userGroupIds: string[] };
  }>(),
);
